<template>
  <div class="row mt-4" v-if="showUsers" >
    <hr/>
    <div class="card" style="border: 3px solid green; margin-left: 3.5%">
      <div class="card-header">
        <h3>Keeping</h3>
      </div>
      <div style="  margin-bottom: 10px;">
        <div class="card-body">
          <MergeObjectDisplay :display-data="keepingUserDisplay"
                              @valChanged="updateSubmitObject($event)"
                              :index-id-value="1"
                              :radio-checked="true"/>
        </div>
      </div>


    </div>
    <div class="card" style="border: 3px solid red; ">
      <div class="card-header">
        <h3>Removing</h3>
      </div>
      <div style="margin-bottom: 10px;">
        <div class="card-body">

          <MergeObjectDisplay
              :display-data="removingUserDisplay"
              @valChanged="updateSubmitObject($event)"
              :index-id-value="2"
              :radio-checked="false"
          />
        </div>
      </div>
    </div>
    <div class="card" style="border: 3px solid grey; ">
      <div class="card-header">
        <h3>Result Preview</h3>
      </div>
      <div>
        <div class="card-body">

          <!--        <h4>Keeping Values</h4>-->
          <table style="margin: 10px; table-layout: auto;">
            <thead>
            <tr>
              <th style="color: black;">Field Name</th>
              <th style="color: black;">Content</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(val, key) in userToKeep" :key="key">
              <td v-if="showKeyInResult(key)" style="color: black;">{{ keys[key] }}</td>
              <td v-if="showKeyInResult(key)" style="color: black;">{{ val }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="button btn-primary bi-link-45deg" type="submit" @click="showMergeModal = true">Merge</button>
  </div>
  <div v-if="showMergeModal">
    <Teleport to="body">
      <UserMergeModal
          :auth-token="authToken"
          :id-keeping-user="idKeepingUser"
          :id-removing-user="idRemovingUser"
          :user-data="userToKeep"
          :show="showMergeModal"
          @close="showMergeModal = false"
          @mergeCall="mergeHelper"/>
    </Teleport>
  </div>
</template>

<script>
import {ref, onBeforeMount, computed} from "vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";
import {mergeUser} from "@/Helpers/ApiCalls/UserAPICalls";
import router from "@/router";
import Swal from "sweetalert2";
import {isEmpty} from "lodash/lang";
import {useStore} from "vuex";
import MergeObjectDisplay from "@/components/Merge/MergeObjectDisplay";
import UserMergeModal from "@/components/User/UserMerge/UserMergeModal";

export default {
  name: "UserMergeDisplay",
  components: {UserMergeModal, MergeObjectDisplay},
  props: {
    idKeepingUser: String,
    idRemovingUser: String,
  },
  setup(props) {
    const store = useStore();
    const keepingUserId = ref(-1);
    const removingUserId = ref(-1);
    const keepingUserDisplay = ref({});
    const removingUserDisplay = ref({});
    const userToKeep = ref({});
    const keys = ref([]);
    const orgIdsKeepingDisplay = ref({});
    const orgIdsRemovingDisplay = ref({});
    const authToken = store.getters['auth/authenticated'];
    const showMergeModal = ref(false);

    onBeforeMount(() => {
      getUsers();
    })

    async function getUsers() {
      let user_one_temp;
      let get_uri = API_URL + "/user/byId?_idUser=";
      await axios.get(get_uri + parseInt(atob(props.idKeepingUser)))
          .then((result) => {
            user_one_temp = result.data;
            userToKeep.value = result.data;
            orgIdsKeepingDisplay.value = {
              idEsu: result.data.idEsu,
              idDistrict: result.data.idDistrict,
              idSchool: result.data.idSchool,
              idOutstateOrganization: result.data.idOutstateOrganization,
              idPosition: result.data.roleHierarchyId,
              stateCode: result.data.stateCode,
              countryCode: result.data.countryCode
            }
          })

      let user_two_temp;
      await axios.get(get_uri + parseInt(atob(props.idRemovingUser)))
          .then((result) => {
            user_two_temp = result.data;
            orgIdsRemovingDisplay.value = {
              idEsu: result.data.idEsu,
              idDistrict: result.data.idDistrict,
              idSchool: result.data.idSchool,
              idOutstateOrganization: result.data.idOutstateOrganization,
              roleHierarchyId: result.data.roleHierarchyId,
              stateCode: result.data.stateCode,
              countryCode: result.data.countryCode
            }
          })

      let diff_keys = Object.keys(user_one_temp).filter(k => user_one_temp[k] !== user_two_temp[k]);

      let user_one = {};
      for (const prop in user_one_temp) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");
        let add_val = showKeyInResult(prop);


        if (add_val) {
          user_one[prop] = {
            value: user_one_temp[prop],
            highlight: diff_keys.includes(prop) && (!prop.includes('Date') && !prop.includes('date')),
            label: result
          }
        }
      }

      let user_two = {}

      for (const prop in user_two_temp) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");
        let add_val = showKeyInResult(prop);


        if (add_val) {
          user_two[prop] = {
            value: user_two_temp[prop],
            highlight: diff_keys.includes(prop) && (!prop.includes('Date') && !prop.includes('date')),
            label: result
          }
          keys.value[prop] = result;
        }
      }

      keepingUserDisplay.value = user_one;
      removingUserDisplay.value = user_two;
      keepingUserId.value = parseInt(atob(props.idKeepingUser));
      removingUserId.value = parseInt(atob(props.idRemovingUser))
    }


    function mergeHelper() {
      delete userToKeep.value.enrollmentType;
      Swal.fire({
        title: 'Confirm Merge',
        text: 'Are you sure?',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        showCancelButton: true
      }).then((res) => {
        if (res.isConfirmed) {
          commitMerge();
        }
      })
    }

    async function commitMerge() {
      await axios.put(mergeUser(parseInt(atob(props.idKeepingUser)), parseInt(atob(props.idRemovingUser))), userToKeep.value, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: "Successfully Merged",
            icon: "success"
          }).finally(() => {
            router.push({name: 'UserSearch'})
          })
        }
      })
    }

    const showUsers = computed(() => {
      return !isEmpty(keepingUserDisplay.value) && !isEmpty(removingUserDisplay.value);
    })

    function updateSubmitObject(event) {
      userToKeep.value[event.key] = event.value;

      if (event.key === 'esuName') {
        if (event.value !== keepingUserDisplay.value.esuName.value) {
          userToKeep.value.idEsu = orgIdsRemovingDisplay.value.idEsu;
        } else {
          userToKeep.value.idEsu = orgIdsKeepingDisplay.value.idEsu;
        }
      } else if (event.key === 'districtName') {
        if (event.value !== keepingUserDisplay.value.districtName.value) {
          userToKeep.value.idDistrict = orgIdsRemovingDisplay.value.idDistrict;
        } else {
          userToKeep.value.idDistrict = orgIdsKeepingDisplay.value.idDistrict;
        }
      } else if (event.key === 'schoolName') {
        if (event.value !== keepingUserDisplay.value.schoolName.value) {
          userToKeep.value.idSchool = orgIdsRemovingDisplay.value.idSchool;
        } else {
          userToKeep.value.idSchool = orgIdsKeepingDisplay.value.idSchool;
        }
      } else if (event.key === 'outStateOrgName') {
        if (event.value !== keepingUserDisplay.value.outStateOrgName.value) {
          userToKeep.value.idoutstateOrganization = orgIdsRemovingDisplay.value.idoutstateOrganization;
        } else {
          userToKeep.value.idoutstateOrganization = orgIdsKeepingDisplay.value.idoutstateOrganization;
        }
      } else if (event.key === 'positionName') {
        if (event.value !== keepingUserDisplay.value.positionName.value) {
          userToKeep.value.roleHierarchyId = orgIdsRemovingDisplay.value.roleHierarchyId;
        } else {
          userToKeep.value.roleHierarchyId = orgIdsKeepingDisplay.value.roleHierarchyId;
        }
      } else if (event.key === "stateName") {
        if (event.value !== keepingUserDisplay.value.stateCode) {
          userToKeep.value.stateCode = orgIdsRemovingDisplay.value.stateCode;
        } else {
          userToKeep.value.stateCode = orgIdsKeepingDisplay.value.stateCode;
        }
      } else if (event.key === "countryName") {
        if (event.value !== keepingUserDisplay.value.countryCode) {
          userToKeep.value.countryCode = orgIdsRemovingDisplay.value.countryCode;
        } else {
          userToKeep.value.countryCode = orgIdsKeepingDisplay.value.countryCode;
        }
      }
    }

    function showKeyInResult(key) {
      return !key.includes('id') && !key.includes('Code') && !key.includes('Id') && !key.includes('status')
          && !key.includes('enabled') && !key.includes('enrollmentType') && !key.includes('enrollmentSelectionValue')
          && !key.includes('Enabled') && !key.includes('Nebraska') && !key.includes('role');
    }

    return {
      keepingUserId,
      keepingUserDisplay,
      removingUserId,
      removingUserDisplay,
      userToKeep,
      keys,
      showUsers,
      showMergeModal,
      authToken,
      mergeHelper,
      updateSubmitObject,
      showKeyInResult
    }
  }
}
</script>

<style scoped>
td {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  height: 50px;
  padding: 2px;
  border: 1px solid black;
}

th {
  border: 1px solid black;
}

.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  /*max-height: 800px;*/
  /*!*height: 250px;*!*/
  /*width: 250px;*/
  /*border-radius: 0;*/
  padding: 0;
  /*border: 1px solid rgb(0, 0, 0);*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*padding: 1rem 1rem;*/

}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}

</style>